function Links()
{
  return (
    <>
      <hr className="my-4" />
      <h2 className="font-bold text-md text-center mb-2">Links úteis</h2>
      <ul className="text-center text-xs">
        {[
          { href: "/nova-senha", title: "Nova senha" },
          { href: "/politica-de-privacidade", title: "Política de privacidade" },
          { href: "/termos-de-uso", title: "Termos de uso" },
          { href: "/lgpd", title: "LGPD" }
        ].map((item, index) => (
          <li key={index} className="mb-1">
            <a className="underline text-xs" href={item.href}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Links;
