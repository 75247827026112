import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import Base from "./pages/base";
import Password from "./pages/password";
import Terms from "./pages/terms";
import Policy from "./pages/policy";
import LegislationProtectData from "./pages/legislation_protect_data";
import Error from "./pages/error";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter(
[{
    path: "/",
    element: <Base />,
    errorElement: <Error />,
    children:
    [
      {
        path: "/nova-senha",
        element: <Password />
      },
      {
        path: "/termos-de-uso",
        element: <Terms />
      },
      {
        path: "/politica-de-privacidade",
        element: <Policy />
      },
      {
        path: "/lgpd",
        element: <LegislationProtectData />
      }
    ]
}]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
