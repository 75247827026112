import withHTMLContent from "../hoc/withHTMLContent";

function Policy({ className })
{
  return (
    <h1 className={`font-bold text-2xl ${className}`}>
      Política de privacidade
    </h1>
  );
}

export default withHTMLContent(Policy, "privacy-policy");
