import { useState } from "react";
import axios from "axios";

function App() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const [values, setValues] = useState({
    password_token: token,
    new_password: "",
    password_confirmation: "",
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { new_password, password_confirmation } = values;

    if (new_password.length === 0 || password_confirmation.length === 0) {
      alert("Digeite uma nova senha");
      return;
    }

    if (new_password !== password_confirmation) {
      alert("As senhas não coincidem");
      return;
    }

    setLoading(true);

    const { REACT_APP_BASE_URL, REACT_APP_X_API_KEY } = process.env;

    try {
      const response = await axios.request({
        url: `${REACT_APP_BASE_URL}/customer/register-password`,
        method: "POST",
        data: values,
        headers: {
          "content-type": "application/json",
          "x-api-key": REACT_APP_X_API_KEY,
        },
      });

      const { data, status } = response;

      if (status < 300) {
        alert(data.result);
      } else {
        alert("Erro! Favor entrar em contato com os desenvolvedores.");
      }
    } catch (error) {
      const { data } = error?.response;

      if (data) {
        if (data.error && typeof data.error === "string") {
          alert(data.error);
        } else {
          alert(data.statusText ?? "BAD REQUEST");
        }
      } else {
        alert("Erro! Favor entrar em contato com os desenvolvedores.");
      }
    }

    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="card w-screen md:w-96 h-fit bg-base-100 md:shadow-2xl">
      <div className="card-body p-6">
        <h1 className="text-xl font-bold text-center mb-4">Nova senha</h1>
        <form onSubmit={handleSubmit} noValidate>
          <div className="flex flex-col gap-4">
            <input
              type="password"
              name="new_password"
              placeholder="Nova senha"
              className="input input-bordered w-full"
              onChange={(e) => handleChange(e)}
            />
            <input
              type="password"
              name="password_confirmation"
              placeholder="Repita a nova senha"
              className="input input-bordered w-full"
              onChange={(e) => handleChange(e)}
            />
            <input type="hidden" value={window.token} />
            <button
              type="submit"
              className="btn"
              disabled={token === null || token.length === 0 || loading}
            >
              {loading ? (
                <>
                  <span className="loading loading-spinner"></span>Enviando...
                </>
              ) : (
                "Mudar senha"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
