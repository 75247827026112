import { Outlet } from "react-router-dom";
import Main from "../layout/main";

function Base()
{
  return (
    <Main>
      <Outlet />
    </Main>
  );
}

export default Base;
