import axios from "axios";
import { useEffect, useState } from "react";

const withHTMLContent = (Component, endPoint) => () => {
  const [html, setHTML] = useState(null);

  const getContent = async () => {
    const { REACT_APP_BASE_URL, REACT_APP_X_API_KEY } = process.env;

    const response = await axios.request({
      url: REACT_APP_BASE_URL + "/" + endPoint,
      method: "GET",
      headers: {
        "x-api-key": REACT_APP_X_API_KEY,
      },
    });

    const { result, meta } = response.data;
    if (meta.code <= 300) {
      setHTML(result.text);
      return;
    }

    alert("Erro ao tentar carregar o conteúdo");
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <Component className="mb-4" />
      {html ? (
        <div
          className="w-full xl:max-w-4xl"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      ) : (
        <span className="loading loading-spinner loading-sm mx-auto block" />
      )}
    </>
  );
};

export default withHTMLContent;
