import Main from "../layout/main";

function Error()
{
  return (
    <Main>
      <h1 className="font-bold text-2xl">Oops!</h1>
      <p>Página não encontrada.</p>
      <hr className="border-1 border-gray-700 w-5 my-4" />
    </Main>
  );
}

export default Error;
