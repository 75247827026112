import Links from "../components/links";

function Main({ children }) {
  return (
    <main className="flex flex-col gap-2 items-center">
      {children}
      <div className="mt-4">
        <Links />
      </div>
    </main>
  );
}

export default Main;
